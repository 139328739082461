import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import axios from 'axios';

import SEO from '../../components/SEO';
import Header from '../../components/BasicPage/Header';
import Wrapper from '../../components/Wrapper';
import ArchiveList from '../../components/Archive/List';
import ArticleTease from '../../components/ArticleTease';
import Footer from '../../components/Footer';
import NumberedPagination from '../../components/NumberedPagination';

const repositoryName = process.env.GATSBY_PRISMIC_REPOSITORY_NAME || 'cfs-website';

const NewsAndMediaListingByTag = ({ data, location }) => {
  const [articles, setArticles] = useState([]);
  const [numPages, setNumPages] = useState(null);

  const pressArchive = data.prismic.pressArchive.edges.slice(0, 1).pop();

  const { seo } = pressArchive.node;

  let tagToFilterBy = null;
  let page = 1;

  if (typeof document !== 'undefined') {
    tagToFilterBy = document.location.hash.replace('#', '') || null;
    page = parseInt(document.location.search.split('?p=')[1]) || 1;
  }

  // Get content from Prismic API on the fly (via AJAX after page load)
  useEffect(() => {
    // Get the Prismic master ref and staging ref from the API
    axios.get(`https://${repositoryName}.prismic.io/api/v2`).then(resp => {
      const masterRef = resp.data.refs[0].ref;
      const stagingRef = resp.data.refs[1].ref;

      if (document.location.hostname === 'localhost') {
        console.log('PRISMIC REF:');
        console.log(stagingRef);
      }

      const prismicRef = document.location.hostname === 'localhost' ? stagingRef : masterRef;
      const resultsPerPage = 10;
      const prismicAPIEndpoint = `https://${repositoryName}.prismic.io/api/v2/documents/search?ref=${prismicRef}&q=%5B%5Bat(document.type%2C+%22press%22)%5D%5D&q=%5B%5Bat(document.tags%2C+%5B%22${tagToFilterBy}%22%5D)%5D%5D&page=${page}&pageSize=${resultsPerPage}#format=json`;

      if (!tagToFilterBy) {
        document.location.href = '/news-and-media';
      } else {
        axios.get(prismicAPIEndpoint).then(resp => {
          setArticles(resp.data.results);
          setNumPages(Math.ceil(resp.data.total_results_size / resultsPerPage));
        });
      }
    });
  }, []);

  return (
    <>
      <SEO
        title="Press"
        description={seo && seo.description ? seo.description : undefined}
        image={seo && seo.image ? seo.image : undefined}
        href={location.href}
      />

      {pressArchive && (
        <Header
          image={pressArchive.node.header_image}
          sharpImage={pressArchive.node.header_imageSharp}
          overline={pressArchive.node.breadcrumb_title}
          title={pressArchive.node.title}
        />
      )}

      <Wrapper>
        {articles.length > 0 ? (
          <ArchiveList>
            {articles.map(articleItem => {
              const article = { ...articleItem.data };
              article.id = articleItem.id;
              article.type = articleItem.type;
              article.sourceAuthor = article.source_author;

              article._meta = {
                uid: articleItem.uid,
                type: articleItem.type,
                tags: [...articleItem.tags],
              };

              return <ArticleTease key={article.id} article={article} />;
            })}
          </ArchiveList>
        ) : (
          <p>No articles</p>
        )}
      </Wrapper>

      <NumberedPagination
        numPages={numPages}
        currentPage={page}
        onPageChange={(e, data) => {
          // eslint-disable-next-line react/prop-types
          if (data.activePage === 1) {
            navigate(`/news-and-media/tag#${tagToFilterBy}`);
            return;
          }

          // eslint-disable-next-line react/prop-types
          navigate(`/news-and-media/tag?p=${data.activePage}#${tagToFilterBy}`);
        }}
      />

      <Footer cta={pressArchive && pressArchive.node.cta} />
    </>
  );
};

NewsAndMediaListingByTag.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      stickyPress: PropTypes.shape({
        edges: PropTypes.array,
      }),
      allPresss: PropTypes.shape({
        edges: PropTypes.array,
      }),
      pressArchive: PropTypes.shape({
        edges: PropTypes.array,
      }),
    }),
  }),
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    previousPagePath: PropTypes.string,
    nextPagePath: PropTypes.string,
  }),
};

export const query = graphql`
  query NewsAndMediaListingArchiveQuery {
    prismic {
      pressArchive: allPress_archive_pages {
        edges {
          node {
            seo {
              ...PageSEOQuery
            }
            title
            breadcrumb_title
            header_image
            header_imageSharp {
              ...FeaturePhotoQuery
            }
            cta {
              ...FooterCta
            }
          }
        }
      }
    }
  }
`;

export default NewsAndMediaListingByTag;
